import React, { useEffect, useRef, useState } from 'react';
import './Menu.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  ToyotaLogo,
  CallCenter,
  NewCar,
  OldCar,
  Quality,
  TestDrive,
  Calculator,
} from '@assets/svg';
import {
  selectIsShowFullSizeMenu,
  setIsShowFullSizeMenu,
} from '@store/menu/slice';
import {
  resetFilters as resetNewCarsFilters,
  setModificationId,
} from '@store/newCars/slice';
import { resetFilters as resetUsedCarsFilters } from '@store/usedCars/slice';
import { useGetMenuQuery } from '@store/dealers/api';
import {
  menuList,
  selectedDealer,
  selectedDealerName,
  selectedZone,
} from '@store/dealers/slice';
import Settings from '@assets/svg/Settings';
import Basket from '@assets/svg/Basket';
import AccessoriesIcon from '@assets/svg/AccessoriesIcon';
import 'swiper/css';
import 'swiper/css/pagination';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
} from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import NavigationSwiperIconPrev from '@assets/svg/NavigationSwiperIconPrev';
import { setClearConsultationModalData } from '@store/consultationModal/slice';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectFade]);
import { initGTMforButtons } from '@utils/initGTMforButtons';

const routes = [
  {
    route: (dealer: string) => `/new-cars`,
    indexPath: '/new-cars',
    icon: <NewCar />,
    text: 'Нові автомобілі',
  },
  {
    route: (dealer: string) => `/used-cars/list`,
    indexPath: '/used-cars',
    icon: <OldCar />,
    text: 'Автомобілі з пробігом',
  },
  {
    route: (dealer: string) => `/service`,
    indexPath: '/service',
    icon: <CallCenter />,
    text: 'Сервіс',
  },
  // {
  //   to: BASE_PAGES.CONFIGURATOR.path,
  //   route: BASE_PAGES.CONFIGURATOR.route,
  //   icon: <Settings />,
  //   text: "Конфігуратор",
  // },
  // {
  //   to: BASE_PAGES.CREDIT_AND_INSURANCE.path,
  //   route: BASE_PAGES.CREDIT_AND_INSURANCE.route,
  //   icon: <Calculator />,
  //   text: "Кредит і страхування",
  // },
  // {
  //   to: BASE_PAGES.TRADE_IN.path,
  //   route: BASE_PAGES.TRADE_IN.route,
  //   icon: <TradeIn />,
  //   text: "Trade-in",
  // },
  // {
  //   route: (dealer: string) => `/test-drive`,
  //   indexPath: '/test-drive',
  //   icon: <TestDrive />,
  //   text: "Тест-драйв",
  // },
  // {
  //   to: BASE_PAGES.ACCESSORIES.path,
  //   route: BASE_PAGES.ACCESSORIES.route,
  //   icon: <Basket />,
  //   text: "Аксесуари",
  // },
  {
    route: (dealer: string) => `/promotions`,
    indexPath: '/promotions',
    icon: <Quality />,
    text: 'Акції та інформаційні розділи',
  },
];

const Menu = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const isShowFullSizeMenu = useSelector(selectIsShowFullSizeMenu);
  const dealerCode = useSelector(selectedDealer);
  const dealerName = useSelector(selectedDealerName);
  const zoneCode = useSelector(selectedZone);
  const routesItems = useSelector(menuList);
  const [noSwiping, setNoSwiping] = useState(false);
  const [initializedSlider, setInitializedSlider] = useState(false);

  const swiperRef = useRef(null);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const toggleMenu = () => dispatch(setIsShowFullSizeMenu(!isShowFullSizeMenu));

  const handleClick = (to: string, text: string) => {
    initGTMforButtons({
      eventType: 'custom_click',
      click_text: text,
    });
    dispatch(resetNewCarsFilters());
    dispatch(resetUsedCarsFilters());
    dispatch(setModificationId(null));
    dispatch(setClearConsultationModalData());
    navigate(to);
  };

  const { isLoading: isLoadingMenu } = useGetMenuQuery({
    dealer_code: dealerCode,
    zone: zoneCode,
  });

  const isLoading = React.useMemo(() => isLoadingMenu, [isLoadingMenu]);

  const newMenu: any = [];

  const mapRoutes = (item: any) => {
    switch (item.template) {
      case 'new_cars_main_page':
        return newMenu.push({
          route: (dealer: string) => `/new-cars`,
          indexPath: '/new-cars',
          icon: <NewCar />,
          text: item.label,
        });
      case 'configurator_main':
        return newMenu.push({
          route: (dealer: string) => `/configurator`,
          indexPath: '/configurator',
          icon: <Settings />,
          text: item.label,
        });
      case 'service_main_page':
        return newMenu.push({
          route: (dealer: string) => `/service`,
          indexPath: '/service',
          icon: <CallCenter />,
          text: item.label,
        });
      case 'used_cars_categories':
        return newMenu.push({
          route: (dealer: string) => `/used-cars`,
          // route: (dealer: string) => `/used-cars/list`,
          indexPath: '/used-cars',
          icon: <OldCar />,
          text: item.label,
        });
      case 'trade_in':
        return newMenu.push({
          route: (dealer: string) => `/trade-in`,
          indexPath: '/trade-in',
          icon: <Quality />,
          text: item.label,
        });
      case 'test_drive_main':
        return newMenu.push({
          route: (dealer: string) => `/test-drive`,
          indexPath: '/test-drive',
          icon: <TestDrive />,
          text: item.label,
        });
      case 'promotions_main_page':
        return newMenu.push({
          route: (dealer: string) => `/promotions`,
          indexPath: '/promotions',
          icon: <Quality />,
          text: item.label,
        });
      case 'lending_and_insurance_main_page':
        return newMenu.push({
          route: (dealer: string) => `/credit-and-insurance`,
          indexPath: '/credit-and-insurance',
          icon: <Calculator />,
          text: item.label,
        });
      case 'new_cars_model_accessories':
        return newMenu.push({
          route: (dealer: string) => `/accessory`,
          indexPath: '/accessory',
          icon: <AccessoriesIcon />,
          text: item.label,
        });
    }
  };

  const pagination = {
    clickable: true,
    renderBullet: function (index: any, className: any) {
      return (
        '<span class="' +
        className +
        ' custom-bullet" style="height: calc(100% / ' +
        newMenu.length +
        ')">' +
        (index + 1) +
        '</span>'
      );
    },
  };

  const initSliderHendler = (swiper: any) => {
    console.log('swiper: ', swiper);
    //@ts-ignore
    setInitializedSlider(swiper.initialized);
  };

  useEffect(() => {
    if (initializedSlider && swiperRef) {
      //@ts-ignore
      // swiperRef.current.navigation.init();
    }
  }, [swiperRef, initializedSlider]);

  const nextSlide = () => {
    //@ts-ignore
    swiperRef.current.swiper.navigation.slideNext();
  };

  // function calculateScrollOffset(activeSlideIndex: any) {
  //   const slidesToShow = 6; // Количество отображаемых слайдов
  //   const scrollStep = 104; // Шаг скролла между слайдами
  //
  //   // Преобразовываем индекс слайда так, чтобы он находился в диапазоне от 0 до 6
  //   const adjustedIndex = activeSlideIndex % slidesToShow;
  //
  //   // Вычисляем смещение скролла на основе индекса активного слайда
  //   const scrollOffset = adjustedIndex * scrollStep;
  //   console.log('scrollOffset: ', scrollOffset)
  //
  //   return scrollOffset;
  // }

  const handleSlideChange = (swiper: any) => {
    // const swiperScrollbarDrag: any = document.querySelector('.swiper-scrollbar-drag');
    // console.log('Активный слайд: ', swiper.realIndex);
    // // console.log('swiperScrollbarDrag: ', swiperScrollbarDrag)
    //
    //
    //
    // if (swiperScrollbarDrag) {
    //   swiperScrollbarDrag.style.backgroundColor = '#ff0000'; // Изменяем цвет фона
    //   swiperScrollbarDrag.style.width = '8px'; // Изменяем ширину элемента
    //   swiperScrollbarDrag.style.borderRadius = '4px'; // Добавляем скругление углов
    //   swiperScrollbarDrag.style.opacity = '0.8'; // Уменьшаем прозрачность
    //   swiperScrollbarDrag.style.transform = `translate3d(0px, ${calculateScrollOffset(swiper.realIndex)}px, 0px)`; // Уменьшаем прозрачность
    //   // Другие стили, какие вам нужны
    // }
  };

  return (
    <>
      <div className={`menu ${!isShowFullSizeMenu ? 'menu-closed' : ''}`}>
        <button
          className={`close-button ${
            isShowFullSizeMenu ? 'close-button_opened' : 'close-button_closed'
          }`}
          onClick={toggleMenu}
        />
        <div className="menu-logo">
          <div className="logo-wrapper">
            <ToyotaLogo />
          </div>
          {isShowFullSizeMenu ? <p>{dealerName}</p> : null}
        </div>
        <div className="menu-parts">
          {routesItems && routesItems.data.map((item: any) => mapRoutes(item))}
          {newMenu.length > 7 && (
            <div
              className="prev"
              ref={navigationPrevRef}
              //@ts-ignore
              onClick={() => swiperRef.current?.slidePrev()}
            >
              <NavigationSwiperIconPrev />
            </div>
          )}
          <div className="menu-swiper-container">
            <div className="swiper-scrollbar"></div>
            <Swiper
              //@ts-ignore
              ref={swiperRef}
              grabCursor={true}
              scrollbar={{
                el: '.swiper-scrollbar',
                draggable: true,
                dragSize: 104,
              }}
              // onSwiper={(swiper) => {
              //   initSliderHendler(swiper);
              // }}
              onSlideChange={handleSlideChange}
              direction={'vertical'}
              slidesPerView={'auto'}
              initialSlide={0}
              loop={newMenu.length > 6 ? true : false}
              className={
                noSwiping ? 'menu-swiper swiper-no-swiping' : 'menu-swiper'
              }
              onBeforeInit={(swiper) => {
                //@ts-ignore
                swiperRef.current = swiper;
              }}
            >
              {newMenu.length &&
                newMenu.map((route: any, index: any) => {
                  const isActive = pathname.includes(route.indexPath);
                  return (
                    <SwiperSlide>
                      <div
                        key={route.indexPath}
                        className={
                          isActive
                            ? 'menu-parts__part-enabled'
                            : 'menu-parts__part'
                        }
                        onClick={() => handleClick(route.route(dealerCode), route.text)}
                      >
                        <div className="icon">
                          {route.icon ? route.icon : null}
                        </div>
                        {isShowFullSizeMenu ? <p>{route.text}</p> : null}
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>

          {newMenu.length > 7 && (
            <div
              className="next"
              ref={navigationNextRef}
              //@ts-ignore
              onClick={() => swiperRef.current?.slideNext()}
            >
              <NavigationSwiperIconPrev />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Menu;
