import styles from './style.module.scss';
import React from 'react';
import {useSelector} from "react-redux";
import {translationsDataArr} from "@store/translate/slice";
import getTranslateText from "@utils/getTranslateText";
import configurator from "@screens/Configurator";
import { initGTMforButtons } from '../../../../utils/initGTMforButtons';
type Props = {
    activeTab:number;
    changeTab:(id:number)=>void;
};
const ColorCarTab:React.FC<Props>=({changeTab,activeTab})=>{
    const translateArr = useSelector(translationsDataArr);
    const tabItems=[
        {
            id:1,
            label: getTranslateText(translateArr, 'translate.admin.configurator.car.color')
        },
        {
            id:2,
            label: getTranslateText(translateArr, 'translate.admin.configurator.interier.color')
        },
        {
            id:3,
            label: getTranslateText(translateArr, 'translate.admin.configurator.disc.color')
        }
    ]
    return(
        <div className={styles.wrapperTab}>
            {tabItems.map((item,ind)=>{
                return(
                    <button
                        onClick={() => {
                            initGTMforButtons({
                                eventType: 'custom_click',
                                page_name: 'Конфігуратор - Колір',
                                click_text: item.label,
                            });
                            changeTab(item.id);
                        }}
                        key={item.id}
                        className={activeTab === item.id ? `${styles.tabItem} ${styles.active}` : `${styles.tabItem}`}
                    >
                        {item.label}
                    </button>
                )
            })}
        </div>
    )
}
export default ColorCarTab
