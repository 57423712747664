import React, { useEffect } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { MainLayout } from '../components/layout';
import { MainScreen } from '../screens/MainScreen';
import PageNotFound from '../screens/PageNotFound';
import { ROUTES } from './routes';
import { getTranslateData } from '@api/modal';
import { useDispatch } from 'react-redux';
import { setTranslationsData } from '@store/translate/slice';
import getTranslateText from '@utils/getTranslateText';

const UniversalTemplatePage = React.lazy(() => import('../screens/UniversalTemplate'));
const PrivacyPolicyPage = React.lazy(() => import('../screens/PrivacyPolicy'));
const NewCarsPage = React.lazy(() => import('../screens/NewCars'));
const NewCarsListPage = React.lazy(
  () => import('../screens/NewCars/NewCarsList'),
);
const NewCarsChooseEnginePage = React.lazy(
  () => import('../screens/NewCars/ChooseEngine'),
);
const NewCarsChoosePackagePage = React.lazy(
  () => import('../screens/NewCars/ChoosePackage'),
);
const NewCarsPricesAndPackagesPage = React.lazy(
  () => import('../screens/NewCars/Prices'),
);
const NewCarPage = React.lazy(() => import('../screens/NewCars/NewCar'));
const NewCarDescriptionOfModel = React.lazy(
  () => import('../screens/NewCars/NewCar/Description'),
);
const NewCarWarrantyAndServicePage = React.lazy(
  () => import('../screens/NewCars/NewCar/WarrantyService'),
);
const NewCarAccessoriesPage = React.lazy(
  () => import('../screens/NewCars/NewCar/Accessories'),
);
const NewCarPhotoLibraryPage = React.lazy(
  () => import('../screens/NewCars/NewCar/PhotoLibrary'),
);
const NewCarCharacteristicsPage = React.lazy(
  () => import('../screens/NewCars/NewCar/Characteristics'),
);
const NewCarEquipmentPage = React.lazy(
  () => import('../screens/NewCars/NewCar/Equipment'),
);
const NewCarsAtTheDealerPage = React.lazy(
  () => import('../screens/NewCars/DealerCars'),
);
const NewCarsAtTheStockPage = React.lazy(
  () => import('../screens/NewCars/StockCars'),
);
const ConfiguratorPage = React.lazy(() => import('../screens/Configurator'));
const ConfiguratorChoosePackagePage = React.lazy(
  () => import('../screens/Configurator/ChoosePackage'),
);
const ConfiguratorChooseEnginePage = React.lazy(
  () => import('../screens/Configurator/ChooseEngine'),
);
const ConfiguratorChooseCarBodyPage = React.lazy(
  () => import('../screens/Configurator/ChooseCarBody'),
);
const ConfiguratorChooseOptionsPage = React.lazy(
  () => import('../screens/Configurator/ChooseOptions'),
);
const ConfiguratorChooseColorPage = React.lazy(
  () => import('../screens/Configurator/ChooseColor'),
);
const ConfiguratorChooseAccessoriesPage = React.lazy(
  () => import('../screens/Configurator/ChooseAccessories'),
);
const ConfiguratorResultsPage = React.lazy(
  () => import('../screens/Configurator/Results'),
);
const MyConfigurationsListPage = React.lazy(
  () => import('../screens/Configurator/MyConfigurationsList'),
);
const PromotionsAndInformationsPage = React.lazy(
  () => import('../screens/Promotions'),
);
const ServicePage = React.lazy(() => import('../screens/Service'));
const ServiceInfoPage = React.lazy(() => import('../screens/ServiceInfo'));
const ChooseModelPage = React.lazy(
  () => import('../screens/Service/ChooseModel'),
);
const ChooseYearPage = React.lazy(
  () => import('../screens/Service/ChooseYear'),
);
const ServiceChooseEnginePage = React.lazy(
  () => import('../screens/Service/ChooseEngine'),
);
const ServiceMaintenanceCardPage = React.lazy(
  () => import('../screens/Service/MaintenanceInfo'),
);
const TestDrivePage = React.lazy(() => import('../screens/TestDrive'));
const TestDriveChooseEnginePage = React.lazy(
  () => import('../screens/TestDrive/ChooseEngine'),
);
const TestDriveRoutePage = React.lazy(
  () => import('../screens/TestDrive/Route'),
);
const TestDriveRequestPage = React.lazy(
  () => import('../screens/TestDrive/Request'),
);
const TradeInPage = React.lazy(() => import('../screens/TradeIn'));
const UsedCarsPage = React.lazy(() => import('../screens/UsedCars'));

const UsedCarPage = React.lazy(() => import('../screens/UsedCars/UsedCar'));
const UsedKitCarPage = React.lazy(() => import('../screens/UsedCars/KitCar'));
const UsedCarFeaturesOfProgramPage = React.lazy(
  () => import('../screens/UsedCars/ProgramFeatures'),
);
const UsedCarDescriptionOfCarModelPage = React.lazy(
  () => import('../screens/UsedCars/Description'),
);
const UsedCarCharacteristicsPage = React.lazy(
  () => import('../screens/UsedCars/Characteristics'),
);

const PromotionsPage = React.lazy(() => import('../screens/Promotions'));
const PromotionsList = React.lazy(
  () => import('../screens/Promotions/PromotionsList'),
);
const PromotionsListItem = React.lazy(
  () => import('../screens/Promotions/PromotionsListItem'),
);
const ChatBotPage = React.lazy(() => import('../screens/ChatBot'));
const ViewTechnologies = React.lazy(
  () => import('../screens/ViewTechnologies'),
);
const DemonstrateTechnologies = React.lazy(
  () => import('../screens/ViewTechnologies/DemonstrationList'),
);
const DemonstrateCarList = React.lazy(
  () => import('../screens/ViewTechnologies/CarList'),
);

// STOCK CAR COMPONENTS BEGIN
const StockCarPage = React.lazy(
  () => import('../screens/NewCars/StockCars/StockCar'),
);
const StockCarDescriptionOfModel = React.lazy(
  () => import('../screens/NewCars/StockCars/StockCar/Description'),
);
const StockCarCharacteristicsPage = React.lazy(
  () => import('../screens/NewCars/StockCars/StockCar/Characteristics'),
);
const StockCarAccessoriesPage = React.lazy(
  () => import('../screens/NewCars/StockCars/StockCar/Accessories'),
);
const StockCarPhotoLibraryPage = React.lazy(
  () => import('../screens/NewCars/StockCars/StockCar/PhotoLibrary'),
);
const StockCarEquipmentPage = React.lazy(
  () => import('../screens/NewCars/StockCars/StockCar/Equipment'),
);
// STOCK CAR COMPONENTS END

// CreditInsurace START
const CreditAndInsurancePage = React.lazy(
  () => import('@screens/CreditInsurance'),
);
const CreditPage = React.lazy(
  () => import('@screens/CreditInsurance/screens/Credit'),
);
const InsurancePage = React.lazy(
  () => import('@screens/CreditInsurance/screens/Insurance'),
);
const CreditProgramPage = React.lazy(
  () => import('@screens/CreditInsurance/screens/CreditProgram'),
);
const InsuranceProgramPage = React.lazy(
  () => import('@screens/CreditInsurance/screens/InsuranceProgram'),
);
const AccessoriesPage = React.lazy(() => import('@screens/Accesories/index'));
const AccessoriesListPage = React.lazy(
  () => import('@screens/Accesories/Accessories'),
);
const ComparisonPage = React.lazy(() => import('@screens/Comparison/index'));
const ComparisonCarsPage = React.lazy(
  () => import('@screens/Comparison/ChooseCar/index'),
);
const ComparisonEnginesPage = React.lazy(
  () => import('@screens/Comparison/ChooseEngine/index'),
);
const ComparisonPackagesPage = React.lazy(
  () => import('@screens/Comparison/ChoosePackage/index'),
);

// CreditInsurace END

const OutletPage = () => (
  <>
    <Outlet />
  </>
);

const RootNavigator = () => {
  const dispatch = useDispatch();

  const getTranslateSettings = () => {
    getTranslateData().then((res) => {
      dispatch(setTranslationsData(res.data.data));
      // console.log('ololol: ', res.data.data);
    });
  };

  useEffect(() => {
    getTranslateSettings();
  }, []);

  return (
    <Routes>
      <Route path={ROUTES.HOME.route} element={<MainScreen />} />
      <Route path="*" element={<PageNotFound />} />
      <Route element={<MainLayout />}>
        {/* BASE ROUTES START */}
        <Route
          path={ROUTES.PROMOTIONS.route}
          element={<PromotionsAndInformationsPage />}
        />
        <Route
          path={ROUTES.TEST_DRIVE.route}
          element={<TestDrivePage />}
        ></Route>
        <Route
          path={ROUTES.TEST_DRIVE_CHOOSE_ENGINE.route}
          element={<TestDriveChooseEnginePage />}
        />
        <Route
          path={ROUTES.TEST_DRIVE_ROUTE.route}
          element={<TestDriveRoutePage />}
        />
        <Route
          path={ROUTES.TEST_DRIVE_REQUEST.route}
          element={<TestDriveRequestPage />}
        />
        <Route path={ROUTES.TRADE_IN.route} element={<TradeInPage />} />
        <Route
          path={ROUTES.PRIVACY_POLICY.route}
          element={<PrivacyPolicyPage />}
        />
        {/* BASE ROUTES END */}

        {/* CONFIGURATOR START */}
        <Route path={ROUTES.CONFIGURATOR.route} element={<OutletPage />}>
          <Route
            path={ROUTES.CONFIGURATOR_LIST.route}
            element={<ConfiguratorPage />}
          />
          <Route
            path={ROUTES.CONFIGURATOR_CHOOSE_PACKAGE.route}
            element={<ConfiguratorChoosePackagePage />}
          />
          <Route
            path={ROUTES.CONFIGURATOR_CHOOSE_ENGINE.route}
            element={<ConfiguratorChooseEnginePage />}
          />
          <Route
            path={ROUTES.CONFIGURATOR_CHOOSE_CAR_BODY.route}
            element={<ConfiguratorChooseCarBodyPage />}
          />
          <Route
            path={ROUTES.CONFIGURATOR_CHOOSE_OPTIONS.route}
            element={<ConfiguratorChooseOptionsPage />}
          />
          <Route
            path={ROUTES.CONFIGURATOR_CHOOSE_COLOR.route}
            element={<ConfiguratorChooseColorPage />}
          />
          <Route
            path={ROUTES.CONFIGURATOR_CHOOSE_ACCESSORIES.route}
            element={<ConfiguratorChooseAccessoriesPage />}
          />
          <Route
            path={ROUTES.CONFIGURATOR_CHOOSE_RESULTS.route}
            element={<ConfiguratorResultsPage />}
          />
          <Route
            path={ROUTES.CONFIGURATOR_MY_CONFIGURATIONS.route}
            element={<MyConfigurationsListPage />}
          />
        </Route>
        {/* CONFIGURATOR END */}
        {/* NEW CARS START */}
        <Route path={ROUTES.NEW_CARS.route} element={<OutletPage />}>
          <Route path={ROUTES.NEW_CARS.route} element={<NewCarsPage />} />
          <Route
            path={ROUTES.NEW_CARS_LIST.route}
            element={<NewCarsListPage />}
          />
          <Route
            path={ROUTES.NEW_CARS_CHOOSE_ENGINE.route}
            element={<NewCarsChooseEnginePage />}
          />
          <Route
            path={ROUTES.NEW_CARS_CHOOSE_PACKAGE.route}
            element={<NewCarsChoosePackagePage />}
          />
          <Route
            path={ROUTES.NEW_CARS_PRICES_AND_PACKAGES.route}
            element={<NewCarsPricesAndPackagesPage />}
          />
          <Route path={ROUTES.NEW_CAR.route} element={<NewCarPage />} />
          <Route
            path={ROUTES.NEW_CAR_DESCRIPTION_OF_MODEL.route}
            element={<NewCarDescriptionOfModel />}
          />
          <Route
            path={ROUTES.NEW_CAR_ACCESSORIES.route}
            element={<NewCarAccessoriesPage />}
          />
          <Route
            path={ROUTES.NEW_CAR_CHARACTERISTICS.route}
            element={<NewCarCharacteristicsPage />}
          />
          <Route
            path={ROUTES.NEW_CAR_EQUIPMENT.route}
            element={<NewCarEquipmentPage />}
          />
          <Route
            path={ROUTES.NEW_CARS_AT_THE_DEALER.route}
            element={<NewCarsAtTheDealerPage />}
          />
          <Route
            path={ROUTES.NEW_CARS_AT_THE_STOCK.route}
            element={<NewCarsAtTheStockPage />}
          />
          <Route
            path={ROUTES.NEW_CAR_WARRANTY_AND_SERVICE.route}
            element={<NewCarWarrantyAndServicePage />}
          />
          <Route
            path={ROUTES.NEW_CAR_PHOTO_LIBRARY.route}
            element={<NewCarPhotoLibraryPage />}
          />
          {/* STOCK CAR PAGES */}
          <Route path={ROUTES.STOCK_CAR.route} element={<StockCarPage />} />
          <Route
            path={ROUTES.STOCK_CAR_DESCRIPTION_OF_MODEL.route}
            element={<StockCarDescriptionOfModel />}
          />
          <Route
            path={ROUTES.STOCK_CAR_CHARACTERISTICS.route}
            element={<StockCarCharacteristicsPage />}
          />
          <Route
            path={ROUTES.STOCK_CAR_ACCESSORIES.route}
            element={<StockCarAccessoriesPage />}
          />
          <Route
            path={ROUTES.STOCK_CAR_PHOTO_LIBRARY.route}
            element={<StockCarPhotoLibraryPage />}
          />
          <Route
            path={ROUTES.STOCK_CAR_EQUIPMENT.route}
            element={<StockCarEquipmentPage />}
          />
        </Route>
        {/* NEW CARS END */}

        {/* USED CARS START */}
        <Route
          path={ROUTES.USED_CARS.route}
          // path={"/used-cars/:id"}
          element={<OutletPage />}
        >
          <Route
            path={ROUTES.USED_CARS_LIST.route}
            // path={"/used-cars/:id/list"}
            element={<UsedCarsPage />}
          />
          <Route
            path={ROUTES.USED_CAR_INFO.route}
            // path={"/used-cars/:id/car-info"}
            element={<UsedCarPage />}
          />
          <Route
            path={ROUTES.USED_CAR_KIT.route}
            // path={"/used-cars/:id/car-kit"}
            element={<UsedKitCarPage />}
          />
          <Route
            path={ROUTES.USED_CAR_FEATURE_OF_PROGRAM.route}
            // path={"/used-cars/:id/feature-of-program"}
            element={<UsedCarFeaturesOfProgramPage />}
          />
          <Route
            path={ROUTES.USED_CAR_DESCRIPTION_OF_CAR_MODEL.route}
            // path={"/used-cars/:id/description-of-model"}
            element={<UsedCarDescriptionOfCarModelPage />}
          />
          <Route
            path={ROUTES.USED_CAR_CHARACRETISTICS.route}
            // path={"/used-cars/:id/characteristics"}
            element={<UsedCarCharacteristicsPage />}
          />
        </Route>
        {/* USED CARS END */}

        {/* SERVICE START */}
        <Route path={ROUTES.SERVICE.route} element={<ServicePage />} />
        <Route path={ROUTES.SERVICE_INFO.route} element={<ServiceInfoPage />} />
        <Route path={ROUTES.MAINTENANCE.route} element={<ChooseModelPage />} />
        <Route
          path={ROUTES.SERVICE_CHOOSE_YEAR.route}
          element={<ChooseYearPage />}
        />
        <Route
          path={ROUTES.SERVICE_CHOOSE_ENGINE.route}
          element={<ServiceChooseEnginePage />}
        />
        <Route
          path={ROUTES.SERVICE_MAINTENANCE_INFO.route}
          element={<ServiceMaintenanceCardPage />}
        />
        {/* SERVICE END */}

        {/* PROMOTIONS START */}
        <Route path={ROUTES.PROMOTIONS.route} element={<PromotionsPage />} />
        <Route path={ROUTES.CHAT_BOT_INFO.route} element={<ChatBotPage />} />
        <Route
          path={ROUTES.PROMOTIONS_LIST.route}
          element={<PromotionsList />}
        />
        <Route
          path={ROUTES.PROMOTIONS_LIST_ITEM.route}
          element={<PromotionsListItem />}
        />
        <Route
          path={ROUTES.VIEW_TECHNOLOGIES.route}
          element={<ViewTechnologies />}
        />
        <Route
          path={ROUTES.DEMONSTRATE_TECHNOLOGIES.route}
          element={<DemonstrateTechnologies />}
        />
        <Route path={ROUTES.CAR_LIST.route} element={<DemonstrateCarList />} />
        {/* PROMOTIONS END */}

        {/* CREDIT INSURANCE START */}
        <Route
          path={ROUTES.CREDIT_AND_INSURANCE.route}
          element={<CreditAndInsurancePage />}
        />
        <Route path={ROUTES.CREDIT.route} element={<CreditPage />} />
        <Route path={ROUTES.INSURANCE.route} element={<InsurancePage />} />
        <Route
          path={ROUTES.CREDIT_PROGRAM.route}
          element={<CreditProgramPage />}
        />
        <Route
          path={ROUTES.INSURANCE_PROGRAM.route}
          element={<InsuranceProgramPage />}
        />
        {/* CREDIT INSURANCE END */}

        {/* ACCESSORIES INSURANCE START */}
        <Route path={ROUTES.ACCESSORIES.route} element={<AccessoriesPage />} />
        <Route
          path={ROUTES.ACCESSORIES_LIST.route}
          element={<AccessoriesListPage />}
        />
        {/* ACCESSORIES INSURANCE END */}

        {/* COMPARISONS INSURANCE START */}
        <Route path={ROUTES.COMPARISONS.route} element={<OutletPage />}>
          <Route path={ROUTES.COMPARISONS.route} element={<ComparisonPage />} />
          <Route
            path={ROUTES.COMPARISON_CHOOSE_CAR.route}
            element={<ComparisonCarsPage />}
          />
          <Route
            path={ROUTES.COMPARISON_CHOOSE_ENGINE.route}
            element={<ComparisonEnginesPage />}
          />
          <Route
            path={ROUTES.COMPARISON_CHOOSE_PACKAGE.route}
            element={<ComparisonPackagesPage />}
          />
        </Route>
        {/* COMPARISONS INSURANCE END */}
        <Route path={ROUTES.UNIVERSAL_TEMPLATE.route} element={<UniversalTemplatePage />} />
      </Route>
    </Routes>
  );
};

export default RootNavigator;
