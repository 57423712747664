import ColorCarTab from '../tab';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ExteriorColorBothConfigurator,
  ExteriorColorConfigurator,
  IDisc,
} from '../../../../store/configuratorCars/types';
import { INewCarDetailConfigurator } from '../../../../types';
import { PhotoSlider } from '../components';
import './style.scss';
import { useGetStyledCarImagesMutation } from '../../../../store/configuratorCars/api';
import { useLocation, useParams } from 'react-router-dom';
import { NewCarModelShort } from '../../../../store/newCars/types';
import Tooltip from '../../../../components/common/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearStyledCarImages,
  selectCarStyle,
  selectChoosedDisc,
  selectChoosedExterior,
  selectChoosedInterior,
  setDiscColor,
  setExteriorColor,
  setInteriorColor,
} from '../../../../store/configuratorCars/slice';
import ExteriorCar from '../../../../assets/svg/ExteriorCar';
import InteriorCar from '../../../../assets/svg/InteriorCar';
import { ScreenContentLayout, Spinner } from '../../../../components/common';
import { selectedDealer } from '@store/dealers/slice';
import formatNumber from '@utils/formatNumbers';
import {initGTMforButtons} from "@utils/initGTMforButtons";
type Props = {
  photosByColorsExterior: INewCarDetailConfigurator['exterior_images_by_colors'];
  photosByColorsInterior: INewCarDetailConfigurator['interior_images_by_colors'];
  colorsExterior: INewCarDetailConfigurator['exterior_colors'];
  colorsInterior: INewCarDetailConfigurator['interior_colors'];
  discList: IDisc[];
  discDevider: number;
  onlyRead?: boolean;
  modeficationsId?: string;
  carId?: string;
};
const ContentView: React.FC<Props> = ({
  photosByColorsExterior,
  colorsExterior,
  colorsInterior,
  photosByColorsInterior,
  discList,
  discDevider,
  onlyRead,
  modeficationsId,
  carId,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const state = location.state as {
    car: NewCarModelShort & { vehicleCompletion_id: string };
    modefications: string;
    exterior: ExteriorColorConfigurator;
    interior: ExteriorColorConfigurator;
    disc: IDisc;
  };
  const selectedExterior = useSelector(selectChoosedExterior);
  const selectedInterior = useSelector(selectChoosedInterior);
  const selectedDisc = useSelector(selectChoosedDisc);
  const { modefications, car, exterior, interior, disc } = state;
  const params = useParams();
  const dealer = useSelector(selectedDealer);
  const [activeTab, setActiveTab] = useState(1);
  const [activeView, setActiveView] = useState(1);
  const [
    getStyledCarImagesMutation,
    { isError, isLoading: isStyledCarLoading, error },
  ] = useGetStyledCarImagesMutation();
  const activeNameTab = useMemo(() => {
    if (activeTab == 1) {
      return 'colorsExterior';
    }
    if (activeTab == 2) {
      return 'colorsInterior';
    }
    if (activeTab == 3) {
      return 'discs';
    }
    return 'colorsExterior';
  }, [activeTab]);
  const selectedCarStyle = useSelector(selectCarStyle);
  const onChangeTab = (id: number) => {
    setActiveTab(id);
  };
  const currentColors = {
    colorsExterior: colorsExterior,
    colorsInterior: colorsInterior,
    discs: discList
  };
  const currentPhotos = {
    colorsExterior: photosByColorsExterior,
    colorsInterior: photosByColorsInterior,
  };
  const [activeColor, setActiveColor] =
    React.useState<any>({
      colorsExterior: exterior || selectedExterior || colorsExterior[0],
      colorsInterior: interior || selectedInterior || colorsInterior[0],
      discs: disc || selectedDisc || discList[0],
    });
  useEffect(() => {
    dispatch(setExteriorColor(exterior || selectedExterior || colorsExterior[0]));
    dispatch(setInteriorColor(interior || selectedInterior || colorsInterior[0]));
    dispatch(setDiscColor(disc || selectedDisc || discList[0]));
    const exteriorColor = exterior || selectedExterior || colorsExterior[0];
    const interiorColor = interior || selectedInterior || colorsInterior[0];
    const discColor = disc || selectedDisc || discList[0];
    let formData: any = {
      disc: discColor.id,
      exterior_color_id: exteriorColor.id,
      interior_color_id: interiorColor.id,
    };
    getStyledImages(formData);
    return () => {
      setActiveColor({
        colorsExterior: exterior || selectedExterior || colorsExterior[0],
        colorsInterior: interior || selectedInterior || colorsInterior[0],
        discs: disc || selectedDisc || discList[0],
      });
      if (onlyRead) {
        dispatch(clearStyledCarImages(selectedCarStyle));
      } else {
        dispatch(clearStyledCarImages(null));
      }
    };
  }, []);
  const changeActiveColor = async (item: ExteriorColorConfigurator | IDisc, label: string) => {
    initGTMforButtons({
      eventType: 'custom_click',
      page_name: 'Конфігуратор - колір',
      click_text: label,
    });
    let changeName = {
      colorsExterior: 'exterior_color_id',
      colorsInterior: 'interior_color_id',
      discs: 'disc'
    };
    let activeExterior = activeColor.colorsExterior.id;
    let activeInterior = activeColor.colorsInterior.id;
    let activeDisc = activeColor.discs.id;
    let formData = {
      disc: activeDisc,
      exterior_color_id: activeExterior,
      interior_color_id: activeInterior,
      [changeName[activeNameTab]]: item.id,
    };
    // setActiveView(1)
    setActiveColor({ ...activeColor, [activeNameTab]: item });
    if (!onlyRead) {
      switch (activeNameTab) {
        case 'colorsExterior':
          dispatch(setExteriorColor(item));
          break;
        case 'colorsInterior':
          dispatch(setInteriorColor(item));
          break;
        case 'discs':
          dispatch(setDiscColor(item));
          break;
      }
    }
    await getStyledImages(formData);
  };
  const onChangeActiveView = (id: number) => {
    if (activeView !== id) {
      initGTMforButtons({
        eventType: 'custom_click',
        page_name: 'Конфігуратор - Колір',
        click_text: id === 1 ? 'Екстерʼєр' : id === 2 ? 'Інтерʼєр' : '',
      });
      setActiveView(id);
    }
  };
  const getStyledImages = async (formData: any) => {
    try {
      await getStyledCarImagesMutation({
        dealer_dealer_code: dealer,
        vehicleModel_id: car?.id || carId,
        vehicleModification_id: modeficationsId || modefications,
        data: formData,
      }).unwrap();
    } catch (error) {
      console.log('err', error);
    }
  };
  return (
    <div className={'colorContentView'}>
      <div className={'viewTabWrapper'}>
        <button
          className={activeView === 1 ? 'viewBtn active' : 'viewBtn'}
          onClick={() => {
            onChangeActiveView(1);
          }}
        >
          <ExteriorCar />
        </button>
        <button
          className={activeView === 2 ? 'viewBtn active' : 'viewBtn'}
          onClick={() => {
            onChangeActiveView(2);
          }}
        >
          <InteriorCar />
        </button>
      </div>
      {isStyledCarLoading ? (
        <div className={'wrapperLoadCarStyleSpinner'}>
          <Spinner />
        </div>
      ) : (
        <PhotoSlider
          photosByColorsExterior={currentPhotos['colorsExterior']}
          photosByColorsInterior={currentPhotos['colorsInterior']}
          activeColorInterior={activeColor['colorsInterior']}
          activeColorExterior={activeColor['colorsExterior']}
          selectedDisc={activeColor['discs']}
          activeView={activeView}
          onlyRead={onlyRead}
        />
      )}

      <div className={'wrapperTabColors'}>
        <ColorCarTab changeTab={onChangeTab} activeTab={activeTab} />
        <div className="image-block">
          <div className="colors">
            <div className="icons">
              {currentColors[activeNameTab].map((item: any, index: number) => (
                <>
                  <div
                    key={item.id}
                    onClick={() => changeActiveColor(item, activeTab === 3 ? item.name : item.label)}
                    className={`icons__icon ${
                      activeColor[activeNameTab].image === item.image
                        ? 'icons__icon_active'
                        : ''
                    }`}
                  >
                    <img src={item.image} alt={activeTab === 3 ? item.name : item.label} />
                  </div>
                  { activeTab === 3 && discDevider === index + 1 && currentColors[activeNameTab].length > discDevider &&(
                    <div className="disc-devider"></div>
                  )}
                </>
              ))}
            </div>
            <div className={'wrapperLabel'}>
              <span className="colors__color-name">
                {activeTab === 3 ? activeColor[activeNameTab].name : activeColor[activeNameTab].label}
              </span>
              <div className={'wrapperPriceBlock'}>
                {activeColor[activeNameTab].price ? (
                  <span className="price">
                    {activeColor[activeNameTab].discount_price &&
                    activeColor[activeNameTab].discount_price > 0
                      ? activeColor[activeNameTab].discount_price
                      : formatNumber(activeColor[activeNameTab].price)}{' '}
                    грн
                  </span>
                ) : (
                  ''
                )}
                {activeColor[activeNameTab].discount_price &&
                  activeColor[activeNameTab].discount_price > 0 && (
                    <div className={'wrapperDiscount'}>
                      <span className="package__discount">
                        {activeColor[activeNameTab].price} грн
                      </span>
                      {activeColor[activeNameTab]
                        ?.disclaimer_discount_price && (
                        <Tooltip
                          text={
                            activeColor[activeNameTab]
                              ?.disclaimer_discount_price
                          }
                          size={25}
                          position="right"
                        />
                      )}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContentView;
